import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Mission from "../components/Mission";
import Preferences from "../components/Preferences";

import { fetchData } from "../functions/fetchData";

export default function Homepage({
  setSelectedOption,
  selectedOption,
  locales,
  setStep,
  step,
}) {
  const { locale } = useParams();

  const option = locales
    .filter((item) => item.code == locale)
    .map((item) => {
      return { label: item.name, value: item.code };
    });

  const { data } = useQuery(
    `homepage-${locale}`,
    fetchData("/homepage", locale)
  );

  const global = useQuery(`global-${locale}`, fetchData("/global", locale), {
    suspense: true,
  });

  useEffect(() => {
    setStep({ links: [], active: 1 });
    localStorage.setItem("cvf-checked-articles", JSON.stringify([]));
    setSelectedOption(option[0]);
  }, []);

  useEffect(() => {
    document.title = `${data.mission.header} CovidVax Facts`;
  }, []);

  return (
    <>
      <Header
        locales={locales}
        locale={locale}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        data={global.data}
      />
      <Breadcrumb data={global.data.breadcrumb} step={step} />
      <main className="container px-4">
        <Mission data={data.mission} />
        <Preferences locale={locale} data={data.preference} />
      </main>
      <Footer data={global.data.footer} />
    </>
  );
}
