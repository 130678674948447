import React, { Suspense, useState } from "react";
import { useQuery } from "react-query";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";
import Loader from "./components/Loader";

import Homepage from "./pages/Homepage";
import StepFour from "./pages/StepFour";
import StepThree from "./pages/StepThree";
import StepTwo from "./pages/StepTwo";
import StaticPage from "./pages/StaticPage";
import { fetchData } from "./functions/fetchData";

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.send({ hitType: "pageview", page: location.pathname }); // Record a pageview for the given page
  if (window.fbq) {
    window.fbq("track", "PageView");
  }
});
export default function App() {
  const locales = useQuery(`locales`, fetchData("/i18n/locales"), {
    suspense: true,
  });

  const defaultLocale = locales.data.filter((item) => item.isDefault)[0].code;
  const codeLocales = locales.data.map((locale) => locale.code);
  const base = `/:locale(${codeLocales.join("|")})`;

  const [checkedArticles, setArticle] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [step, setStep] = useState();

  return (
    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path={`${base}/feedback/:preferenceId/:articleIds`}>
            <StepFour
              locales={locales.data}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              step={step}
              setStep={setStep}
            />
          </Route>
          <Route path={`${base}/facts/:preferenceId/:articleIds`}>
            <StepThree
              locales={locales.data}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              step={step}
              setStep={setStep}
            />
          </Route>
          <Route path={`${base}/preference/:preferenceId`}>
            <StepTwo
              setStep={setStep}
              setArticle={setArticle}
              checkedArticles={checkedArticles}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              locales={locales.data}
            />
          </Route>
          <Route path={`${base}/:subpage`}>
            <StaticPage
              defaultLocale={defaultLocale}
              locales={locales.data}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </Route>
          <Redirect
            exact
            from="/"
            to={
              codeLocales.includes(navigator.language)
                ? navigator.language
                : defaultLocale
            }
          />
          <Route exact path={base}>
            <Homepage
              setArticle={setArticle}
              defaultLocale={defaultLocale}
              locales={locales.data}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              step={step}
              setStep={setStep}
            />
          </Route>
          <Route>
            <Redirect
              exact
              from="/"
              to={
                codeLocales.includes(navigator.language)
                  ? navigator.language
                  : defaultLocale
              }
            />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
}
