import React, { useState } from "react";
import { useMutation } from "react-query";
import { Formik, Form, Field, ErrorMessage } from "formik";

import AccordionHeader from "./AccordionHeader";

const postData = async (data) => {
  await fetch(`${process.env.REACT_APP_API_URL}/feedbacks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      message: `
CovidVax Facts: Provide your question form

Email: ${data.email}
Name: ${data.name}
Message:
${data.message}
      `,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw response;
      }

      return response;
    });
};

export default function QuestionForm({ page }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((value) => !value);

  const sendForm = useMutation(postData);

  return (
    <li
      className={`${
        open ? "border-orangey-yellow" : "border-soft-blue"
      } mb-6 border-4 rounded-lg`}
    >
      <AccordionHeader
        title={page.textField[0].value}
        open={open}
        toggleOpen={toggleOpen}
      />

      <div
        className={`overflow-hidden transition ${
          open ? "h-auto" : "h-0"
        } bg-white`}
      >
        <div className="w-full px-4 md:px-8 py-4">
          <Formik
            validateOnChange="true"
            initialValues={{ message: "", name: "", email: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.message || !values.email || !values.name) {
                errors.message = page.textField[6].value;
              }
              return errors;
            }}
            onSubmit={(values, { resetForm, setSubmitting }) => {
              sendForm.mutate(values, {
                onSuccess: () => {
                  alert(page.textField[5].value);
                  resetForm();
                },
                onError: (err) => {
                  alert(err.message);
                },
              });
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, errors }) => (
              <Form className="flex flex-col">
                <div>
                  <Field
                    name="name"
                    placeholder={page.textField[1].value}
                    className={`mb-4 w-full border-4 border-soft-blue rounded-xl text-black p-4 resize-none h-full rounded focus:outline-none focus:ring ${
                      errors.message && "focus:ring-red-500"
                    }`}
                  />
                </div>
                <div>
                  <Field
                    type="email"
                    name="email"
                    placeholder={page.textField[2].value}
                    className={`mb-4 w-full border-4 border-soft-blue rounded-xl text-black p-4 resize-none h-full rounded focus:outline-none focus:ring ${
                      errors.message && "focus:ring-red-500"
                    }`}
                  />
                </div>
                <div>
                  <Field
                    className={`mb-4 w-full border-4 border-soft-blue rounded-xl text-black p-4 resize-none h-full rounded focus:outline-none focus:ring ${
                      errors.message && "focus:ring-red-500"
                    }`}
                    as="textarea"
                    cols={10}
                    rows={8}
                    placeholder={page.textField[3].value}
                    name="message"
                  />
                  <div className="p-4">
                    <ErrorMessage
                      name="message"
                      component="span"
                      className="text-red-500"
                    />
                  </div>
                </div>
                <div className="flex justify-end py-4">
                  <button
                    className="bg-orangey-yellow text-dark-slate-blue uppercase font-bold py-3 px-8 rounded-3xl"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {page.textField[4].value}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </li>
  );
}
