import React from "react";
import { Link } from "react-router-dom";
import { checkUrl } from "../functions/validatorUrl";

export default function Preferences({ data, locale }) {
  const { header, subheader, options } = data;

  return (
    <section className="container md:pt-6 md:pb-10">
      <h2 className="text-center text-dark-slate-blue text-4xl font-bold mt-6">
        {header}
      </h2>
      <h3 className="text-center text-dark-slate-blue font-bold text-xl">
        {subheader}
      </h3>
      <ul className="flex flex-col md:flex-row w-full xl:w-4/5 mx-auto gap-x-10">
        {options.map((item) => (
          <li
            key={item.id}
            className="flex-1 w-full bg-white hover:bg-orangey-yellow rounded-2xl border-soft-blue hover:border-orangey-yellow border-4 my-6"
          >
            <Link
              to={{ pathname: `${locale}/preference/${item.id}` }}
              className="w-full h-full pt-8 justify-between flex flex-col text-soft-blue hover:text-white"
            >
              <h3 className="h-full font-bold px-4 text-center text-2xl lg:text-3xl flex items-center justify-center">
                {item.name}
              </h3>
              <div className="aspect-w-4 aspect-h-3 mt-6">
                <img
                  className="rounded-2xl rounded-b-xl"
                  src={checkUrl(item.image.url)}
                  alt={item.image.alternativeText}
                />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </section>
  );
}
