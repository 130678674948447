import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function AccordionHeader({ open, toggleOpen, title }) {
  return (
    <button
      className={`${
        open ? "bg-orangey-yellow text-white" : "bg-white text-soft-blue"
      } items-center justify-between flex px-8 py-3 font-bold md:text-2xl w-full text-left`}
      onClick={toggleOpen}
    >
      <span>{title}</span>
      <FontAwesomeIcon
        className={open ? "rotate-180 transform text-white" : "text-black"}
        icon={faChevronDown}
      />
    </button>
  );
}
