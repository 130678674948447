import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Loader() {
  return (
    <main className="flex justify-center items-center">
      <FontAwesomeIcon className="animate-spin" size="4x" icon={faSpinner} />
    </main>
  );
}
