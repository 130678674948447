import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faShareAlt } from "@fortawesome/free-solid-svg-icons";

import AccordionItem from "../components/AccordionItem";
import Breadcrumb from "../components/Breadcrumb";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";

import { fetchData } from "../functions/fetchData";

export default function StepThree({
  step,
  setStep,
  selectedOption,
  setSelectedOption,
  locales,
}) {
  const { preferenceId, articleIds, locale } = useParams();

  const preferencePath = `/${locale}/preference/${preferenceId}`;
  const feedbackPath = `/${locale}/feedback/${preferenceId}/${articleIds}`;

  const checkedArticles = articleIds
    .split(",")
    .map((articleId) => Number(articleId));

  const urlCurrentArticle = (articleId) =>
    `${window.location.host}/${locale}/facts/${preferenceId}/${articleId}`;

  const [shareUrl, setUrl] = useState({
    value: window.location.href,
    copied: false,
  });

  const option = locales
    .filter((item) => item.code == locale)
    .map((item) => {
      return { label: item.name, value: item.code };
    });

  const { data } = useQuery(
    `accordions-${locale}`,
    fetchData(
      `/articles/`,
      locale,
      `&id_in=${articleIds.split(",").join("&id_in=")}`
    )
  );

  const global = useQuery(`global-${locale}`, fetchData("/global", locale), {
    suspense: true,
  });

  useEffect(() => {
    setStep({ links: ["/", preferencePath], active: 3 });
    setSelectedOption(option[0]);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "cvf-checked-articles",
      JSON.stringify(checkedArticles)
    );
  }, [checkedArticles]);

  useEffect(() => {
    document.title = `CovidVax Facts`;
  }, []);

  return (
    <>
      <Header
        locales={locales}
        locale={locale}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        data={global.data}
      />
      <Breadcrumb data={global.data.breadcrumb} step={step} />
      <main className="mx-auto">
        <section className="px-4">
          <ul>
            {data.map((item) => (
              <AccordionItem
                item={item}
                key={item.id}
                share={urlCurrentArticle(item.id)}
                textButton={[
                  global.data.pages[1].buttons[4].text,
                  global.data.pages[1].buttons[5].text,
                ]}
                textField={global.data.pages[1].textField}
              />
            ))}
          </ul>
          <div className="flex flex-col justify-center md:flex-row md:justify-between mb-10 mt-6 gap-y-4">
            <Button
              direction="left"
              to={preferencePath}
              data={global.data.pages[1].buttons[0].text}
            />
            <CopyToClipboard
              text={shareUrl.value}
              onCopy={() => setUrl({ copied: true })}
            >
              {shareUrl.copied ? (
                <div className="flex-row bg-soft-blue text-white rounded-3xl items-center py-3 px-8 font-bold w-full justify-center flex md:w-1/4">
                  <FontAwesomeIcon className="flex md:text-lg" icon={faCheck} />
                  <span className="flex uppercase px-2">
                    {global.data.pages[1].buttons[3].text}
                  </span>
                </div>
              ) : (
                <button className="flex-row bg-soft-blue text-white rounded-3xl items-center py-3 px-8 font-bold w-full justify-center flex md:w-1/4">
                  <FontAwesomeIcon
                    className="flex md:text-lg"
                    icon={faShareAlt}
                  />
                  <span className="flex uppercase px-2 hover:underline">
                    {global.data.pages[1].buttons[2].text}
                  </span>
                </button>
              )}
            </CopyToClipboard>
            <Button
              direction="right"
              to={feedbackPath}
              data={global.data.pages[1].buttons[1].text}
            />
          </div>
        </section>
      </main>
      <Footer data={global.data.footer} />
    </>
  );
}
