import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import Breadcrumb from "../components/Breadcrumb";
import Button from "../components/Button";
import CheckboxList from "../components/CheckboxList";
import Footer from "../components/Footer";
import Header from "../components/Header";

import { fetchData } from "../functions/fetchData";

export default function StepTwo({
  step,
  setStep,
  setArticle,
  checkedArticles,
  selectedOption,
  setSelectedOption,
  locales,
}) {
  const { preferenceId, locale } = useParams();
  const backBtnLink = `/${locale}`;
  const nextBtnLink = `${backBtnLink}/facts/${preferenceId}/${checkedArticles}`;

  const option = locales
    .filter((item) => item.code == locale)
    .map((item) => {
      return { label: item.name, value: item.code };
    });

  const { data } = useQuery(
    `preferences-${locale}`,
    fetchData(`/preferences/${preferenceId}`, locale)
  );

  const global = useQuery(`global-${locale}`, fetchData("/global", locale), {
    suspense: true,
  });

  const selectAll = () => {
    /**
     * The list of concern items is assembled within CheckboxList,
     * so going through DOM is the easiest way to do this without
     * moving the state somewhere else.
     */
    const visibleCheckboxes = [
      ...document.querySelectorAll("input[type=checkbox]"),
    ].map((node) => parseInt(node.name));

    setArticle(visibleCheckboxes);
  };

  useEffect(() => {
    const storedCheckedArticles =
      JSON.parse(localStorage.getItem("cvf-checked-articles")) || [];

    setArticle(storedCheckedArticles);
    setStep({ links: [backBtnLink], active: 2 });
    setSelectedOption(option[0]);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "cvf-checked-articles",
      JSON.stringify(checkedArticles)
    );
  }, [checkedArticles]);

  useEffect(() => {
    document.title = `${data.pickHeader} CovidVax Facts`;
  }, []);

  return (
    <>
      <Header
        locales={locales}
        locale={locale}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        data={global.data}
      />
      <Breadcrumb data={global.data.breadcrumb} step={step} />
      <main className="container px-4 flex flex-col">
        <section className="px-10 flex-1 h-full items-center justify-center">
          <h2 className="text-2xl md:text-4xl font-black mb-20">
            {data.pickHeader}
          </h2>
          <ul className="flex flex-col md:flex-row mb-8">
            {data.categories.map((item) => (
              <li key={item.id} className="flex-1 mb-6">
                <h3 className="font-bold text-lg md:text-2xl mb-3">
                  {item.name}
                </h3>
                <CheckboxList
                  categoryId={item.id}
                  setArticle={setArticle}
                  checkedArticles={checkedArticles}
                />
              </li>
            ))}
          </ul>
        </section>
        <div className="flex flex-col justify-center md:flex-row md:justify-between mb-10 gap-y-4">
          <Button
            direction="left"
            to={backBtnLink}
            data={global.data.pages[0].buttons[0].text}
          />
          <button
            className="bg-soft-blue text-white uppercase font-bold py-3 px-8 rounded-3xl"
            onClick={selectAll}
          >
            {global.data.pages[0].buttons[2]?.text}
          </button>
          {checkedArticles.length > 0 && (
            <Button
              direction="right"
              to={nextBtnLink}
              data={global.data.pages[0].buttons[1].text}
            />
          )}
        </div>
      </main>
      <Footer data={global.data.footer} />
    </>
  );
}
