import React from "react";
import { fixYouTubeUrl } from "../functions/validatorUrl";

export default function Modal({ active, onClick, src, title }) {
  const rootElement = document.querySelector("#root");

  active
    ? rootElement.classList.add("overflow-hidden")
    : rootElement.classList.remove("overflow-hidden");

  const closeModal = () => {
    document.querySelector("iframe").setAttribute("src", src);
    onClick();
  };

  return (
    <div
      className={`fixed z-50 top-0 left-0 right-0 bottom-0 items-center justify-center ${
        active ? "flex" : "hidden"
      }`}
    >
      <div
        className="absolute bg-gray-900 opacity-75 right-0 bottom-0 top-0 left-0"
        onClick={closeModal}
      ></div>
      <div className="w-full md:w-2/3 mx-auto rounded">
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            title={title}
            src={fixYouTubeUrl(src)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
