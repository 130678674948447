import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Breadcrumb from "../components/Breadcrumb";
import FeedbackForm from "../components/FeedbackForm";
import Footer from "../components/Footer";
import Header from "../components/Header";

import { fetchData } from "../functions/fetchData";
import ReactGA from "react-ga4";

export default function StepFour({
  step,
  setStep,
  selectedOption,
  setSelectedOption,
  locales,
}) {
  const { preferenceId, articleIds, locale } = useParams();

  const checkedArticles = articleIds
    .split(",")
    .map((articleId) => Number(articleId));

  const option = locales
    .filter((item) => item.code == locale)
    .map((item) => {
      return { label: item.name, value: item.code };
    });

  const global = useQuery(`global-${locale}`, fetchData("/global", locale), {
    suspense: true,
  });

  useEffect(() => {
    setStep({
      links: [
        `/${locale}`,
        `/${locale}/preference/${preferenceId}`,
        `/${locale}/facts/${preferenceId}/${articleIds}`,
      ],
      active: 4,
    });
    setSelectedOption(option[0]);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "cvf-checked-articles",
      JSON.stringify(checkedArticles)
    );
  }, [checkedArticles]);

  return (
    <>
      <Header
        locales={locales}
        locale={locale}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        data={global.data}
      />
      <Breadcrumb data={global.data.breadcrumb} step={step} />
      <main className="mx-auto">
        <section className="container px-10 mb-12">
          <div className="w-full md:w-3/4 mx-auto mt-8 mb-10">
            <p className="text-2xl md:text-5xl font-black">
              {global.data.pages[2].textField[0].value}
            </p>
            <p className="text-xl md:text-2xl font-semibold">
              {global.data.pages[2].textField[1].value}
            </p>
          </div>

          <div className="flex flex-col justify-center xl:flex-row xl:justify-between w-full md:w-3/4 mb-20 mx-auto">
            <Link
              to={`/${locale}`}
              className="flex rounded-3xl items-center py-3 px-8 font-bold bg-dark-slate-blue text-white mb-4"
            >
              <FontAwesomeIcon icon={faRedo} />
              <span className="px-2 md:text-xl text-center">
                {global.data.pages[2].buttons[1].text}
              </span>
            </Link>

            <a onClick={() =>  ReactGA.event({category: "interaction", action: "ScheduleClick"})}
              href="https://www.vaccines.gov"
              className="flex rounded-3xl items-center py-3 px-8 font-bold bg-orangey-yellow text-dark-slate-blue mb-4"
            >
              <p className="uppercase px-2 text-xl">
                {global.data.pages[2].buttons[0].text}
              </p>
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          </div>

          <FeedbackForm
            placeholder={global.data.feedbackForm.placeholder}
            submit={global.data.feedbackForm.submitButton}
            image={global.data.feedbackForm.image}
            textValidation={global.data.feedbackForm.textValidation}
            afterSend={global.data.feedbackForm.textAfterSend}
          />
        </section>
      </main>
      <Footer data={global.data.footer} />
    </>
  );
}
