/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function Header({
  locales,
  setSelectedOption,
  selectedOption,
  locale,
  data,
  name,
}) {
  let history = useHistory();

  const options = locales.map((item) => {
    return { label: item.name, value: item.code };
  });

  const handleChange = (selected) => {
    history.push(`/${selected.value}`);
    setSelectedOption(selected);
  };

  const styles = {
    control: (style) => ({
      ...style,
      borderRadius: "0",
      backgroundColor: "#335e91",
      padding: "0",
      cursor: "pointer",
      border: "0",
    }),
    indicatorSeparator: (style) => ({
      ...style,
      display: "none",
    }),
    valueContainer: (style) => ({
      ...style,
      backgroundColor: "#335e91",
      padding: "1rem",
      margin: "0",
      minHeight: "0",
      fontWeight: "700",
    }),
    singleValue: (style) => ({
      ...style,
      color: "#b6c5d8",
    }),
    dropdownIndicator: (style) => ({
      ...style,
      color: "#b6c5d8",
      fontSize: "1.875rem",
      lineHeight: "2.25rem",
      padding: "1rem",
      "&:hover": {
        color: "#b6c5d8",
      },
    }),
    menu: (style) => ({
      ...style,
      marginTop: "0",
    }),
    menuList: (style) => ({
      ...style,
      paddingTop: "0",
      paddingBottom: "0",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faChevronDown} />
      </components.DropdownIndicator>
    );
  };

  return (
    <header className="bg-dark-slate-blue">
      <div className="md:px-4 flex justify-between items-center flex-col md:flex-row">
        <a href={`/${locale}`}>
          <h1 className="text-left text-white font-black text-xl lg:text-3xl py-2 md:py-3 flex-none md:w-74 xl:w-80">
            <span className="text-soft-blue">Your</span>
            <span className="text-orangey-yellow">CoVidVax</span>
            <span className="text-soft-blue">Facts.com</span>
          </h1>
        </a>
        <ul className="flex flex-col md:flex-row gap-y-1 md:gap-y-0 items-center justify-evenly text-sm lg:text-base">
          <li className="px-2 lg:px-4">
            <a
              className={`pb-1 hover:text-orangey-yellow capitalize ${
                !name
                  ? "text-orangey-yellow md:border-orangey-yellow md:border-b-4"
                  : "text-white"
              } `}
              href={`/${locale}`}
            >
              {data.topbar.header}
            </a>
          </li>
          {data.navigation.map((item, index) => (
            <li key={index} className="px-2 lg:px-4">
              <a
                className={`pb-1 hover:text-orangey-yellow capitalize  ${
                  item.url === name
                    ? "text-orangey-yellow md:border-orangey-yellow md:border-b-4"
                    : "text-white"
                } `}
                href={`/${locale}/${item.url}`}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
        <div className="flex-none w-full md:w-max md:pl-26 md:w-74 xl:pl-32 xl:w-80 mt-4 md:mt-0">
          <Select
            classNamePrefix="react-select"
            components={{ DropdownIndicator }}
            options={options}
            onChange={handleChange}
            styles={styles}
            value={selectedOption}
          />
        </div>
      </div>
    </header>
  );
}
