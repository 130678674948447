import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function Checkbox({ data, checked, onClick }) {
  return (
    <label
      className={`inline-flex justify-start items-center ${
        checked
          ? "bg-dark-slate-blue text-white rounded-r-full pr-4"
          : "bg-transparent"
      }`}
    >
      <div
        className={`border-2 rounded border-dark-slate-blue ${
          checked ? "text-white" : "bg-white text-transparent"
        } w-6 h-6 flex flex-shrink-0 justify-center items-center pointer`}
      >
        <FontAwesomeIcon
          icon={faCheck}
          className={`${checked ? "text-white" : "bg-white text-transparent"}`}
        />
        <input
          type="checkbox"
          checked={checked}
          name={data.id}
          onChange={onClick}
          className="cursor-pointer opacity-0 absolute"
        />
      </div>
      <span className="select-none cursor-pointer text-base md:font-bold px-2">
        {data.title}
      </span>
    </label>
  );
}
