import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";

import Checkbox from "./Checkbox";

import { fetchData } from "../functions/fetchData";

export default function CheckboxList({
                                       categoryId,
                                       setArticle,
                                       checkedArticles
                                     }) {
  const { preferenceId, locale } = useParams();

  const toggleChecked = (id) => {
    setArticle(
      checkedArticles.includes(id)
        ? checkedArticles.filter((articleId) => articleId !== id)
        : checkedArticles.concat(id)
    );
  };

  const { data } = useQuery(
    "checkboxList-" + categoryId,
    fetchData(`/categories/${categoryId}`, locale),
    {
      suspense: true
    }
  );

  return (
    <ul>
      {data.articles
        .filter((item) => item.preference === Number(preferenceId))
        .map((item) => (
          <li key={item.id} className="mb-1 md:mb-2">
            <Checkbox
              data={item}
              checked={checkedArticles.includes(item.id)}
              onClick={() => {
                ReactGA.event({
                  action: checkedArticles.includes(item.id) ?
                    "Unselect Concern" : "Select Concern",
                  label: item.title
                });
                toggleChecked(item.id);
              }}
            />
          </li>
        ))}
    </ul>
  );
}
