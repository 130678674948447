import React from "react";
import { checkUrl } from "../functions/validatorUrl";

export default function Footer({ data }) {
  const { header, logos } = data;

  return (
    <>
      <div className="container mt-16">
        <h2 className="font-bold text-black text-xl text-center mb-1">
          {header}
        </h2>
        <ul className="flex flex-col sm:flex-row flex-wrap items-center justify-center">
          {logos &&
            logos.map((item) => (
              <li
                key={item.id}
                className="w-1/3 m-3 sm:w-1/3 md:w-1/6 md:m-6 pointer"
              >
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img
                    src={checkUrl(item.image.url)}
                    alt={item.image.alternativeText}
                    className="w-full"
                  />
                </a>
              </li>
            ))}
        </ul>
        <h5 className="mt-2 mb-8 text-sm text-center">
          Copyright &copy; {new Date().getFullYear()} Jennifer Cunningham Erves,
          PhD, MPH, MAEd, MS, CHES
        </h5>
      </div>
      <div className="w-full bg-dark-slate-blue pt-60"></div>
    </>
  );
}
