import React, { useState } from "react";
import Modal from "./Modal";

import DOMPurify from "dompurify";

import { checkUrl } from "../functions/validatorUrl";

export default function Mission({ data }) {
  const [showModal, setShowModal] = useState(false);
  const { header, content, video = {} } = data;
  return (
    <section className="w-full xl:w-4/5 mx-auto mt-10 flex flex-col lg:flex-row items-stretch bg-teal border-white border-4 rounded-2xl">
      <button
        title="Click to watch video"
        className="flex items-center flex-col w-full lg:w-2/5 xl:w-1/3 flex-none"
        onClick={() => setShowModal(true)}
      >
        <img
          src={video.thumbnail ? checkUrl(video.thumbnail.url) : ""}
          alt={video.thumbnail ? video.thumbnail.alternativeText : ""}
          className="w-full h-full object-cover rounded-xl"
        />
      </button>

      <div className="text-white flex-auto px-20 py-10 flex-auto">
        <h2 className="text-2xl xl:text-4xl font-bold mb-6">{header}</h2>

        <div
          className="reactMarkDown font-bold text-lg"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(content),
          }}
        />
      </div>

      <Modal
        title={video.title}
        src={video.url}
        active={showModal}
        onClick={() => setShowModal(false)}
      />
    </section>
  );
}
