import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function Button({ data, direction, to, className }) {
  return (
    <Link
      to={{ pathname: to }}
      className={`${
        direction === "right"
          ? "flex-row-reverse bg-orangey-yellow text-dark-slate-blue"
          : "flex-row bg-dark-slate-blue text-white"
      } rounded-3xl items-center flex py-3 px-8 font-bold ${className}`}
    >
      <FontAwesomeIcon
        className="flex md:text-lg"
        icon={direction === "right" ? faChevronRight : faChevronLeft}
      />
      <p
        className={`flex uppercase ${direction === "right" ? "pr-2" : "pl-2"}`}
      >
        {data}
      </p>
    </Link>
  );
}
