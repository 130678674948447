import React from "react";

import BreadcrumbItem from "./BreadcrumbItem";

export default function Steps({ data, step = { links: ["/"], active: 2 } }) {
  const { active, links } = step;

  return (
    active && (
      <nav className="container px-4">
        <ul className="breadcrumb">
          {data.map((item, index) => (
            <BreadcrumbItem
              key={item.id}
              content={`${index + 1}. ${item.label}`}
              active={index + 1 === active}
              link={links[index]}
              light={index + 1 >= active}
            />
          ))}
        </ul>
      </nav>
    )
  );
}
