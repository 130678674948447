import React from "react";
import { Link } from "react-router-dom";

export default function BreadcrumbItem({ active, content, light, link }) {
  return (
    <li className={`breadcrumb__item ${active && "breadcrumb__item--active"}`}>
      {light ? (
        <span
          className={`breadcrumb__text breadcrumb__text--light ${
            active && "breadcrumb__text--active"
          }`}
        >
          {content}
        </span>
      ) : (
        <Link
          to={link}
          className={`breadcrumb__text breadcrumb__text--dark ${
            active && "breadcrumb__text--active"
          }`}
        >
          {content}
        </Link>
      )}
    </li>
  );
}
