import React from "react";
import { useMutation } from "react-query";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { checkUrl } from "../functions/validatorUrl";

const postData = async (data) => {
  await fetch(`${process.env.REACT_APP_API_URL}/feedbacks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.error) {
        throw response;
      }

      return response;
    });
};

export default function FeedbackForm({
  placeholder,
  submit,
  image,
  afterSend,
  textValidation,
}) {
  const sendFeedback = useMutation(postData);

  return (
    <Formik
      validateOnChange="true"
      initialValues={{ message: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.message) {
          errors.message = textValidation;
        }
        return errors;
      }}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        sendFeedback.mutate(values, {
          onSuccess: () => {
            alert(afterSend);
            resetForm();
          },
          onError: (err) => {
            alert(err.message);
          },
        });
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, errors }) => (
        <Form className="flex flex-col mx-auto w-full md:w-3/4">
          <div className="flex flex-col md:flex-row">
            <div className="hidden md:flex md:w-4/12 justify-center items-center p-10">
              <img src={checkUrl(image.url)} alt={image.alternativeText} />
            </div>
            <div className="w-full md:w-8/12">
              <Field
                className={`w-full border-4 border-soft-blue rounded-xl text-black p-4 resize-none h-full rounded focus:outline-none focus:ring ${
                  errors.message && "focus:ring-red-500"
                }`}
                as="textarea"
                cols={10}
                placeholder={placeholder}
                name="message"
              />
              <div className="p-4">
                <ErrorMessage
                  name="message"
                  component="span"
                  className="text-red-500"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end py-4">
            <button
              className="bg-orangey-yellow text-dark-slate-blue uppercase font-bold py-3 px-8 rounded-3xl"
              type="submit"
              disabled={isSubmitting}
            >
              {submit}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
