/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { checkUrl } from "../functions/validatorUrl";

import DOMPurify from "dompurify";

export default function AccordionContent({
  share,
  item,
  open,
  toggleModal,
  textButton,
}) {
  const [shareUrl, setUrl] = useState({
    value: share,
    copied: false,
  });

  return (
    <div
      className={`overflow-hidden transition ${
        open ? "h-auto" : "h-0"
      } bg-white`}
    >
      <div className="flex flex-col w-full px-4 md:px-8 py-4">
        <div
          className="reactMarkDown"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(item.content),
          }}
        />
        <div className="flex gap-x-10 flex-col sm:flex-row">
          <div
            className="reactMarkDown flex-i1"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(item.questions),
            }}
          />
          <div className="flex-initial lg:w-80 md:w-60">
            <div className="flex flex-col">
              {item.video.map((video, index) => (
                <button
                  key={video.id}
                  onClick={() =>
                    toggleModal({
                      active: true,
                      title: video.title,
                      src: video.url,
                    })
                  }
                  className={`bg-gray p-4 hover:underline w-full mt-${
                    index > 0 ? "4" : "0"
                  }`}
                >
                  <div className="aspect-w-16 aspect-h-9 w-full mb-2">
                    <img
                      src={checkUrl(video?.thumbnail?.url)}
                      alt={video.title}
                      className="w-full h-full object-center object-contain"
                    />
                  </div>
                  <span className="text-center mt-2 font-bold">
                    {video.title}
                  </span>
                </button>
              ))}
            </div>
          </div>
        </div>

        <CopyToClipboard
          text={shareUrl.value}
          onCopy={() => setUrl({ copied: true })}
        >
          {shareUrl.copied ? (
            <div className="flex items-center">
              <FontAwesomeIcon icon={faCheck} />
              <span className="ml-1">{textButton[1]}</span>
            </div>
          ) : (
            <button className="flex items-center mt-4">
              <FontAwesomeIcon icon={faShareAlt} />
              <span className="ml-1 hover:underline">{textButton[0]}</span>
            </button>
          )}
        </CopyToClipboard>
      </div>
    </div>
  );
}
