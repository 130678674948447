import React, { useState } from "react";

import AccordionHeader from "./AccordionHeader";
import AccordionContent from "./AccordionContent";
import Modal from "./Modal";
import ReactGA from "react-ga4";

export default function AccordionItem({ item, share, textButton, textField }) {
  const [modal, setDataModal] = useState({});
  const toggleModal = (value) => setDataModal(value);

  const [open, setOpen] = useState(true);
  const toggleOpen = () => {
    ReactGA.event({
      category: "interaction",
      action: "ConcernView",
      label: item.title
    });
    setOpen((value) => !value);
  }

  return (
    <li
      key={item.id}
      className={`${
        open ? "border-orangey-yellow" : "border-soft-blue"
      } mb-6 border-4 rounded-lg`}
    >
      <AccordionHeader title={item.title} open={open} toggleOpen={toggleOpen} />
      <AccordionContent
        share={share}
        item={item}
        open={open}
        toggleModal={toggleModal}
        textButton={textButton}
        textField={textField}
      />
      <Modal
        title={modal.title}
        src={modal.src}
        active={modal.active}
        onClick={() => toggleModal({ active: false })}
      />
    </li>
  );
}
