import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import DOMPurify from "dompurify";

import Header from "../components/Header";
import QuestionForm from "../components/QuestionForm";
import BookTalkForm from "../components/BookTalkForm";
import Footer from "../components/Footer";

import { fetchData } from "../functions/fetchData";

export default function DefaultPage({
  setSelectedOption,
  selectedOption,
  locales,
}) {
  const { locale, subpage } = useParams();
  const option = locales
    .filter((item) => item.code == locale)
    .map((item) => {
      return { label: item.name, value: item.code };
    });

  const global = useQuery(`global-${locale}`, fetchData("/global", locale), {
    suspense: true,
  });

  const staticPageContent = global.data.navigation.filter(
    (item) => item.url == subpage
  )[0];

  useEffect(() => {
    setSelectedOption(option[0]);
  }, []);

  useEffect(() => {
    document.title = `${staticPageContent.header}: CovidVax Facts`;
  }, []);

  return (
    <>
      <Header
        locales={locales}
        locale={locale}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        data={global.data}
        name={subpage}
      />
      <main className="container px-4 mt-10">
        <section
          className={`px-10 flex-1 h-full items-center justify-center w-full xl:w-4/5 mx-auto static-${subpage}`}
        >
          <h2 className="text-2xl md:text-4xl font-black my-6">
            {staticPageContent.header}
          </h2>
          <div
            className="reactMarkDown"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(staticPageContent.content),
            }}
          />

          {subpage === "contact" ? (
            <ul>
              <QuestionForm page={global.data.pages[3]} />
              <BookTalkForm page={global.data.pages[4]} />
            </ul>
          ) : (
            <></>
          )}
        </section>
      </main>
      <Footer data={global.data.footer} />
    </>
  );
}
